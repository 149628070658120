import { state } from ":mods";
import { DashboardDynamics, SideLayerCompProps } from "./models";
import { SideLayerElement } from "./components/side-layer-element";
import { getOwner, onCleanup, runWithOwner, untrack } from "solid-js";
import { model as NavModel } from "../navigation";
export const $dashboard = state.createGlobal<DashboardDynamics>({});
export const $dashboard_active_layer_item_idx = state.createGlobal<number>(undefined);
let base_layer_temp = undefined;
let base_layer_temp_bc = undefined;
let layer_active = false;
const default_layout = {
  title: undefined,
  sideInfo: undefined,
  overflow: undefined,
  titleBorder: undefined,
};
export const _dashboard_actions = {
  getActiveLayerItem() {
    const layer = $dashboard.unwrap.layer;
    if (!layer) return undefined;
    if ($dashboard_active_layer_item_idx.value === undefined || $dashboard_active_layer_item_idx.value === null) {
      return undefined;
    }
    return layer.items[$dashboard_active_layer_item_idx.unwrap];
  },

  getTempLayer() {
    return base_layer_temp;
  },
  tempLayerIsActive() {
    return base_layer_temp !== undefined && base_layer_temp !== null;
  },
  clearLayer() {
    // console.log("layer cleaned up entirely");
    base_layer_temp = undefined;
    base_layer_temp_bc = undefined;
    layer_active = false;
    $dashboard.set((s) => ({ ...s, layer: undefined, breadcrumb: undefined }));
  },
  cleanupLayout() {
    const owner = getOwner();
    // console.log(owner);
    runWithOwner(owner, () => {
      onCleanup(() => {
        // console.log("layout cleaned up");
        this.clearLayout();
      });
    });
  },
  clearLayout() {
    $dashboard.set((s) => ({ ...s, ...default_layout }));
  },
  cleanupLayer() {
    if (!layer_active) {
      layer_active = true;
      runWithOwner(getOwner(), () => {
        onCleanup(() => {
          if (layer_active) {
            // console.log("layer cleaned up");
            this.leaveLayer();
          }
        });
      });
    }
  },
  leaveLayer() {
    if (this.tempLayerIsActive()) {
      $dashboard.set((s) => ({ ...s, layer: base_layer_temp, breadcrumb: base_layer_temp_bc }));
      base_layer_temp = undefined;
    } else {
      $dashboard.set((s) => ({ ...s, layer: undefined, breadcrumb: undefined }));
      layer_active = false;
    }
  },
  moveLayerIdx(next: boolean) {
    if (!layer_active) {
      return;
    }
    if (this.tempLayerIsActive()) {
      this.leaveLayer();
    }
    const layer = $dashboard.unwrap.layer;
    const active_idx = $dashboard_active_layer_item_idx.unwrap;
    let next_idx = next ? active_idx + 1 : active_idx - 1;
    if (next_idx < layer.items.length && next_idx >= 0) {
      // console.log("moving next item");
    } else {
      if (layer.allowLoopItemsOnNextAndPrevious) {
        if (next) {
          next_idx = 0;
        } else {
          next_idx = layer.items.length - 1;
        }
      } else {
        next_idx = -1;
      }
    }

    if (next_idx === -1) {
      if (next) {
        layer.events?.onNextNotFound?.();
      } else {
        layer.events?.onPreviousNotFound?.();
      }
      return;
    }

    $dashboard_active_layer_item_idx.set(next_idx);
    if (next) {
      layer.events?.onNext?.();
    } else {
      layer.events?.onPrevious?.();
    }
  },
};

export const dashboard_actions = {
  // TODO: _dashboard_actions.cleanupLayout is causing errors if setLayout or  updateLayout is used inside of createEffect by developer
  // figure out how to warn user of such behaviour by detecting whether these two functions are ran inside createEffect or not.
  setLayout(props: Pick<DashboardDynamics, "title" | "sideInfo" | "overflow" | "titleBorder">) {
    const p: typeof props = { ...default_layout, ...props };
    _dashboard_actions.cleanupLayout();
    $dashboard.set((s) => ({ ...s, ...p }));
  },
  updateLayout(props: Partial<Pick<DashboardDynamics, "title" | "sideInfo" | "overflow" | "titleBorder">>) {
    // _dashboard_actions.cleanupLayout();
    untrack(() => {
      $dashboard.set((s) => ({ ...s, ...props }));
    });
  },
  clearLayout() {
    _dashboard_actions.clearLayout();
  },
  clearLayer() {
    _dashboard_actions.clearLayer();
  },
  getSideNavStatus() {
    return $dashboard.unwrap.sideNavStatus;
  },
  updateSideNavStatus(props: NavModel.SideNavStatus): void {
    if (!props) {
      return;
    }
    $dashboard.set((s) => ({
      ...s,
      sideNavStatus: props,
    }));
  },
  createLayer(props: Omit<SideLayerCompProps, "items" | "base">) {
    if (base_layer_temp) {
      throw new Error("temp layer is created, you need to exit temp layer first!");
    }
    $dashboard.set((s) => ({ ...s, layer: props as any }));
    _dashboard_actions.cleanupLayer();
    return SideLayerElement;
  },
  createLayerTemp(props: Omit<SideLayerCompProps, "items" | "base">) {
    // if (base_layer_temp) {
    //   throw new Error("temp layer is created, you need to exit temp layer first!");
    // }
    base_layer_temp = $dashboard.unwrap.layer;
    base_layer_temp_bc = $dashboard.unwrap.breadcrumb;
    layer_active = true;

    $dashboard.set((s) => ({ ...s, layer: props as any }));
    _dashboard_actions.cleanupLayer();
    return SideLayerElement;
  },
  layer: {
    next() {
      _dashboard_actions.moveLayerIdx(true);
    },
    previous() {
      _dashboard_actions.moveLayerIdx(false);
    },
  },
};
